.itemHeader {
  display: flex;
  align-items: center;
}

.itemHeader img {
  border-radius: 8px;
}

.itemCategory {
}

.itemName {
  font-weight: bold;
  font-size: 30px;
}

.itemDescription {
  margin-top: 10px;
  margin-bottom: 10px;
}

@media only screen and (max-width: 900px) {
  .itemCategory {
    font-size: 13px;
  }
  .itemName {
    font-size: 25px;
  }
}
