.deepdungeon {
  position: relative;
  width: 60%;
  margin: 0 auto 10px auto;
  color: white;
}

.deepdungeonBackground {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 15px solid transparent;
  border-image: url(../Assets/DeepDungeon/deep_dungeon_menu.png) 13 fill stretch;
  box-sizing: border-box;
}

.deepdungeonContent {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 10px;
  padding-bottom: 20px;
  box-sizing: border-box;
}

.deepdungeonLogo {
  margin: auto;
  max-width: 70%;
  content: url(../Assets/DeepDungeon/deep_dungeon.png);
}

.deepdungeonHeaderContainer {
  width: 60%;
  max-width: 400px;
  display: flex;
  margin: 0px auto;
}

.deepdungeonType {
  display: flex;
  width: 80%;
  max-width: 420px;
  height: 30px;
  margin: 0px auto;
}

.deepdungeonType img {
  width: 100%;
  object-fit: contain;
}

.deepdungeonArrowLeft {
  width: 10%;
  object-fit: contain;
  content: url(../Assets/DeepDungeon/arrow.png);
  transform: scale(70%, 70%);
  cursor: url(../Assets/Cursor/cursor-link.png) 10 0, auto;
}

.deepdungeonArrowRight {
  width: 10%;
  object-fit: contain;
  content: url(../Assets/DeepDungeon/arrow.png);
  transform: scale(70%, 70%) scaleX(-1);
  cursor: url(../Assets/Cursor/cursor-link.png) 10 0, auto;
}

.deepdungeonArrowLeft:active {
  margin-top: 4px;
}

.deepdungeonArrowRight:active {
  margin-top: 4px;
}

.deepdungeonCategoryHeader {
  display: flex;
  justify-content: space-between;
  margin: 10px 5% auto 5%;
  font-family: "eurostile-extended";
  text-transform: uppercase;
  font-size: 25px;
  align-items: end;
}

.deepdungeonList {
  display: flex;
  width: 80%;
  margin: 10px auto;
  flex-wrap: wrap;
}

.deepdungeonItemContainer {
  display: flex;
  position: relative;
  width: 50px;
  height: 50px;
  align-items: center;
  justify-content: center;
}

.deepdungeonItemSelected {
  display: block;
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 23px;
  background-color: rgba(255, 255, 255, 0.3);
  box-shadow: 0px 0px 10px 1px rgba(255, 255, 255, 0.5);
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

.deepdungeonItemContainer img {
  position: relative;
  width: 40px;
  cursor: url(../Assets/Cursor/cursor-link.png) 10 0, auto;
}

.deepdungeonItemDetail {
  display: flex;
  width: 80%;
  margin: 0 auto;
  padding: 10px;
  background-color: rgb(12, 16, 67);
  border-radius: 20px;
  align-items: center;
  box-sizing: border-box;
}

.deepdungeonItemDetail img {
  width: 80px;
  margin-right: 10px;
}

.deepdungeonItemDetail h1 {
  font-size: 25px;
  margin: 0;
}

.deepdungeonItemDetail p {
  margin: 0;
}

.deepdungeonSwap {
  background-color: white;
  color: rgb(42, 45, 108);
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  cursor: url(../Assets/Cursor/cursor-link.png) 10 0, auto;
}

.deepdungeonFloorSelect {
  margin: 10px 0 10px 5%;
  width: 140px;
  padding: 10px 20px 10px 0px;
  text-align: center;
  background-color: rgb(83, 85, 136);
  color: white;
  outline: none;
  border: none;
  border-radius: 10px;
  font-size: 20px;
  background: url(../Assets/DeepDungeon/arrow_menu.png) no-repeat right
    rgb(83, 85, 136);
  background-size: 20px;
  appearance: none;
  -webkit-appearance: none;
  background-position-x: 110px;
  cursor: url(../Assets/Cursor/cursor-link.png) 10 0, auto;
}

.deepdungeonFloorMonstersContainer {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 5px auto;
  padding: 10px;
  padding-left: 15px;
  background-color: rgb(12, 16, 67);
  border-radius: 20px;
  box-sizing: border-box;
}

.deepdungeonThreat {
  width: 20px;
  margin-right: 2px;
}

.deepdungeonAggro {
  width: 20px;
  margin-right: 5px;
}

.deepdungeonMonster {
  margin-bottom: 3px;
  width: fit-content;
}

.deepdungeonMonsterName {
  display: flex;
  align-items: center;
  font-size: 18px;
  cursor: url(../Assets/Cursor/cursor-link.png) 10 0, auto;
}

.deepdungeonMonsterAbilities {
  margin-left: 24px;
  margin-bottom: 10px;
}

.deepdungeonBossName {
  font-size: 20px;
  cursor: url(../Assets/Cursor/cursor-link.png) 10 0, auto;
}

.deepdungeonBossAbilities {
  margin-left: 10px;
}

.deepdungeonAbilityToggleContainer {
  display: flex;
}

.deepdungeonAbilityToggle {
  display: inline-block;
  background-color: white;
  color: rgb(42, 45, 108);
  padding-left: 5px;
  padding-right: 5px;
  border-radius: 5px;
  font-size: 20px;
  margin: 3px;
  cursor: url(../Assets/Cursor/cursor-link.png) 10 0, auto;
}

.deepdungeonMonsterAbility {
  width: 100%;
  margin-top: 4px;
  margin-bottom: 4px;
}

.deepdungeonMonsterAbilityName {
  background-color: white;
  color: rgb(42, 45, 108);
  padding-left: 3px;
  padding-right: 3px;
  border-radius: 3px;
  font-weight: bold;
  margin-right: 5px;
  height: fit-content;
}

@media only screen and (max-width: 900px) {
  .deepdungeon {
    width: 95%;
  }
  .deepdungeonHeaderContainer {
    width: 95%;
    margin-bottom: 0;
  }
  .deepdungeonType {
    width: 95%;
    margin-top: 0;
  }
  .deepdungeonCategoryHeader {
    font-size: 15px;
  }
  .deepdungeonItemDetail {
    width: 95%;
  }
  .deepdungeonItemDetail h1 {
    font-size: 18px;
  }
  .deepdungeonItemDetail p {
    font-size: 12px;
  }
  .deepdungeonFloorSelect {
    width: 110px;
    font-size: 15px;
    background-position-x: 80px;
    padding: 5px 35px 5px 5px;
  }

  .monstersHeader {
    margin-top: 0px;
  }
  .deepdungeonAbilityToggle {
    font-size: 15px;
  }
}
