@font-face {
  font-family: "myriad";
  src: url("./Fonts/myriadpro-regular-webfont.woff2") format("woff2"),
    url("./Fonts/myriadpro-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "trajan";
  src: url("./Fonts/trajanpro-regular-webfont.woff2") format("woff2"),
    url("./Fonts/trajanpro-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "eurostyle";
  src: url("./Fonts/eurostyle_normal-webfont.woff2") format("woff2"),
    url("./Fonts/eurostyle_normal-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "trump";
  src: url("./Fonts/TrumpSoftPro.woff2") format("woff2"),
    url("./Fonts/TrumpSoftPro.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "eurostile";
  src: url("./Fonts/eurostile.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "eurostile-extended";
  src: url("./Fonts/EurostileExtended-Roman.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "geogrotesque";
  src: url("./Fonts/GeogrotesqueExp-Md.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "geogrotesque-semibold";
  src: url("./Fonts/GeogrotesqueExp-SmBd.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

html {
  height: 100%;
  cursor: url(Assets/Cursor/cursor-pointer.png) 4 0, auto;
}

body {
  height: 100%;
}

.mainContent {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 20px;
  min-height: calc(100vh - 137px);
}

.themeLight {
  background-color: #ffffff;
  color: rgb(129, 107, 67);
}

.themeDark {
  background-color: #191919;
  color: rgb(182, 168, 108);
}

.themeDark2 {
  background-color: #191919;
  color: rgb(216, 207, 186);
}

::selection {
  background-color: rgb(110, 146, 59);
  color: white;
}

input {
  cursor: url(Assets/Cursor/cursor-text.png) 16 16, auto;
}

.App {
  text-align: left;
  font-family: "myriad";
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.recipeArrowUp {
  width: 0px;
  height: 0px;
  border-bottom: 10px solid black;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  cursor: pointer;
  margin: 5px;
}

.recipeArrowDown {
  width: 0px;
  height: 0px;
  border-top: 10px solid black;
  border-left: 10px solid transparent;
  border-right: 10px solid transparent;
  cursor: pointer;
  margin: 5px;
}

.sectionArrowUp {
  width: 0px;
  height: 0px;
  border-bottom: 15px solid black;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  cursor: pointer;
  margin: 5px;
}

.sectionArrowDown {
  width: 0px;
  height: 0px;
  border-top: 15px solid black;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  cursor: pointer;
  margin: 5px;
}

.sectionArrowContainer {
  display: inline-flex;
  width: 30px;
  height: 30px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  margin-right: 5px;
}

.sectionClosed {
  width: 0px;
  height: 0px;
  border-top: 15px solid transparent;
  border-left: 15px solid black;
  border-bottom: 15px solid transparent;
}

.sectionOpened {
  width: 0px;
  height: 0px;
  border-top: 15px solid black;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
}

.sectionMenuElement {
  cursor: pointer;
}

.sectionMenuElement:hover {
  background-color: rgb(220, 220, 220);
}

.recipeMenuMove {
  position: absolute;
  top: 0;
  left: 70px;
  border: 1px solid black;
  white-space: nowrap;
  background-color: white;
}

.recipeMenuMoveElement {
  padding: 5px;
}

.recipeMenuMoveElement:hover {
  background-color: rgb(220, 220, 220);
}

.menu {
  position: relative;
  height: 640px;
  width: 900px;
  border: 30px solid transparent;
  border-radius: 15px;
  border-image: url(Assets/menu_border.png) 45 round;
  background-image: url(Assets/menu_background.png);
  box-sizing: border-box;
  text-shadow: 1px 1px rgb(24, 24, 24);
  margin: 0 auto;
  color: rgb(220, 220, 220);
}

.menuContainer {
  margin-top: -22px;
  margin-bottom: -22px;
  margin-left: -20px;
  margin-right: -20px;
}

.craftingLogName {
  display: inline-block;
  font-family: "trump";
  font-size: 26px;
  font-stretch: 200%;
  margin: 0;
  margin-top: -3px;
  transform: scale(1, 0.9);
  color: rgb(195, 195, 195);
}

.craftingLogJobName {
  font-family: "trajan";
  margin: 0;
  float: right;
  font-size: 23px;
  transform: scale(0.8, 1);
  color: rgb(183, 180, 145);
}

.closeButton {
  width: 21px;
  height: 21px;
  background: url(Assets/log_spritesheet2.png) -57px -4px;
  background-size: 105px;
  float: right;
  margin-right: -2px;
  margin-left: 10px;
  cursor: url(Assets/Cursor/cursor-link.png) 10 0, auto;
}

.headerIconCRP {
  width: 24px;
  height: 24px;
  background: url(Assets/doh_dol_job_icons.png) 28.5px 0px;
  background-size: 186px;
  float: right;
  margin-right: -9px;
}

.headerIconBSM {
  width: 24px;
  height: 24px;
  background: url(Assets/doh_dol_job_icons.png) -3px 0px;
  background-size: 186px;
  float: right;
  margin-right: -9px;
}

.headerIconARM {
  width: 24px;
  height: 24px;
  background: url(Assets/doh_dol_job_icons.png) -34.5px 0px;
  background-size: 186px;
  float: right;
  margin-right: -9px;
}

.headerIconGSM {
  width: 24px;
  height: 24px;
  background: url(Assets/doh_dol_job_icons.png) -67.5px 0px;
  background-size: 186px;
  float: right;
  margin-right: -9px;
}

.headerIconLTW {
  width: 24px;
  height: 24px;
  background: url(Assets/doh_dol_job_icons.png) -96px -1.5px;
  background-size: 186px;
  float: right;
  margin-right: -9px;
}

.headerIconWVR {
  width: 24px;
  height: 24px;
  background: url(Assets/doh_dol_job_icons.png) -127.5px 0px;
  background-size: 186px;
  float: right;
  margin-right: -9px;
}

.headerIconALC {
  width: 24px;
  height: 24px;
  background: url(Assets/doh_dol_job_icons.png) -4.5px -31.5px;
  background-size: 186px;
  float: right;
  margin-right: -9px;
}

.headerIconCUL {
  width: 24px;
  height: 24px;
  background: url(Assets/doh_dol_job_icons.png) -34.5px -30px;
  background-size: 186px;
  float: right;
  margin-right: -9px;
}

.levelText {
  font-family: "eurostile";
  float: right;
  margin-right: 5px;
  font-size: 22px;
  transform: scale(1, 0.5);
  margin-top: 7px;
}

.craftingLogJobList {
  margin-top: 5px;
}

.craftingLogJob {
  display: inline-block;
  position: relative;
  width: 31px;
  height: 31px;
  margin-right: 3px;
  cursor: url(Assets/Cursor/cursor-link.png) 10 0, auto;
}

.listIconBackground {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 28px;
  height: 28px;
  background: url(Assets/log_spritesheet2.png) -120px -98px;
  background-size: 210px;
}

.listIconBorder {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 31px;
  height: 31px;
  background: url(Assets/log_spritesheet2.png) -119px -62px;
  background-size: 210px;
}

.listIconCRP {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  background: url(Assets/doh_dol_job_icons.png) 28px -1px;
  background-size: 189px;
}

.listIconBSM {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  background: url(Assets/doh_dol_job_icons.png) -4px -1.5px;
  background-size: 189px;
}

.listIconARM {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  background: url(Assets/doh_dol_job_icons.png) -36px -1.5px;
  background-size: 189px;
}

.listIconGSM {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  background: url(Assets/doh_dol_job_icons.png) -66.5px -2px;
  background-size: 189px;
}

.listIconLTW {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  background: url(Assets/doh_dol_job_icons.png) -98.5px -2px;
  background-size: 189px;
}

.listIconWVR {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  background: url(Assets/doh_dol_job_icons.png) -130px -0.5px;
  background-size: 189px;
}

.listIconALC {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  background: url(Assets/doh_dol_job_icons.png) -4px 30px;
  background-size: 189px;
}

.listIconCUL {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  background: url(Assets/doh_dol_job_icons.png) -35.5px 30.5px;
  background-size: 189px;
}

.listIconSearch {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 24px;
  height: 24px;
  background: url(Assets/log_spritesheet1.png) -0.5px -102px;
  background-size: 100px;
}

.craftingLogDividerHorizontal {
  width: 100%;
  height: 2px;
  background-color: rgb(77, 77, 77);
  box-shadow: 0px -1px rgb(23, 23, 23);
  margin: 0;
  margin-top: 2px;
}

.craftingLogDividerVertical {
  display: inline-block;
  width: 2px;
  height: 548px;
  background-color: rgb(77, 77, 77);
  box-shadow: -1px 0px rgb(23, 23, 23);
  margin: 0;
}

.craftingLogMainBody {
  display: flex;
}

.craftingLogLeftBar {
  display: inline-block;
  width: 160px;
  margin-right: 5px;
  margin-top: 10px;
}

.recipeSearchContainer {
  background-color: rgb(39, 39, 39);
  width: 100%;
  height: 52px;
  border-radius: 6px;
  box-shadow: inset 0 2px 2px 0px rgb(27, 27, 27), 0 1px 2px 0 rgb(70, 70, 70);
}

.recipeSearchName {
  font-family: "trajan";
  color: rgb(157, 157, 157);
  font-size: 20px;
  transform-origin: left;
  transform: scale(0.8, 1);
  margin: 0;
  white-space: nowrap;
  margin-left: 5px;
}

.craftingLogSectionName {
  font-family: "trajan";
  color: rgb(157, 157, 157);
  font-size: 19px;
  transform-origin: left;
  transform: scale(0.8, 1);
  margin: 0;
  white-space: nowrap;
}

.recipeSearchInput {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  width: 135px;
  height: 17px;
  margin-left: 8px;
  background-color: rgb(85, 85, 85);
  border-radius: 4px;
  border: none;
  box-shadow: inset 0px -1px rgb(102, 102, 102),
    inset 0px 1px 2px 1px rgb(68, 68, 68);
  color: white;
  padding-left: 5px;
}

.recipeSearchInput:focus {
  outline: 2px solid white;
  outline-offset: -1px;
  box-shadow: inset 0 0 3px 3px rgb(179, 148, 65), 0 0 3px 3px rgb(179, 148, 65);
}

.craftingLogExtrasContainer {
  margin-top: 13px;
  margin-left: 2px;
}

.craftingLogExtrasList {
  margin-top: 3px;
}

.craftingLogSectionSlot {
  position: relative;
  height: 20px;
  width: 150px;
  cursor: url(Assets/Cursor/cursor-link.png) 10 0, auto;
}

.craftingLogSectionSlot:hover > label {
  display: block;
}

.craftingLogSectionText {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  position: absolute;
  margin-left: 13px;
  font-size: 14px;
  transform: scale(1, 0.9);
  color: rgb(216, 207, 186);
}

.craftingLogSectionHover {
  display: none;
  position: absolute;
  height: 23px;
  width: 100%;
  border: 11px solid transparent;
  border-image: url(Assets/highlight.png) 20 fill stretch;
  pointer-events: none;
  top: -2px;
  box-sizing: border-box;
}

.craftingLogSectionActive {
  position: absolute;
  height: 23px;
  width: 100%;
  border: 11px solid transparent;
  border-image: url(Assets/highlight_active.png) 20 fill stretch;
  pointer-events: none;
  top: -2px;
  box-sizing: border-box;
}

.craftingLogRecipesContainer {
  margin-top: 18px;
}

.craftingLogTabsContainer {
  position: relative;
  display: flex;
  width: calc(100%);
  height: 31px;
  margin-top: 7px;
  cursor: url(Assets/Cursor/cursor-link.png) 10 0, auto;
}

.craftingLogTabsBorder {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 17px solid transparent;
  border-image: url(Assets/regular_border.png) 40 round;
  box-sizing: border-box;
  pointer-events: none;
}

.craftingLogTabsInnerContainer {
  position: relative;
  display: flex;
  width: 100%;
  height: 100%;
  cursor: url(Assets/Cursor/cursor-link.png) 10 0, auto;
}

.craftingLogTabsInnerContainer:active {
  margin-top: 2px;
}

.craftingLogTabs {
  display: inline-flex;
  position: relative;
  width: calc(100% / 3);
  height: 32px;
  align-items: center;
}

.craftingLogTabsLeftBackground {
  margin-top: 1px;
  margin-left: 2px;
  width: calc(100% - 2px);
  height: calc(100% - 4px);
  border: 10px solid transparent;
  border-image-source: url(Assets/tab_silver_left.png);
  border-image-slice: 12 fill;
  border-image-repeat: round;
  box-sizing: border-box;
}

.craftingLogTabsLeftBackgroundActive {
  margin-top: 1px;
  margin-left: 2px;
  width: calc(100% - 2px);
  height: calc(100% - 4px);
  border: 10px solid transparent;
  border-image-source: url(Assets/tab_gold_left.png);
  border-image-slice: 12 fill;
  border-image-repeat: round;
  box-sizing: border-box;
}

.craftingLogTabsMiddleBackground {
  margin-top: 1px;
  width: 100%;
  height: calc(100% - 3px);
  border: 10px solid transparent;
  border-image-source: url(Assets/tab_silver_mid.png);
  border-image-slice: 7 fill;
  border-image-repeat: stretch;
  box-sizing: border-box;
}

.craftingLogTabsMiddleBackgroundActive {
  margin-top: 1px;
  width: 100%;
  height: calc(100% - 3px);
  border: 10px solid transparent;
  border-image-source: url(Assets/tab_gold_mid.png);
  border-image-slice: 7 fill;
  border-image-repeat: stretch;
  box-sizing: border-box;
}

.craftingLogTabsRightBackground {
  margin-top: 1px;
  margin-right: 2px;
  width: calc(100% - 2px);
  height: calc(100% - 4px);
  border: 10px solid transparent;
  border-image-source: url(Assets/tab_silver_right.png);
  border-image-slice: 12 fill;
  border-image-repeat: round;
  box-sizing: border-box;
}

.craftingLogTabsRightBackgroundActive {
  margin-top: 1px;
  margin-right: 2px;
  width: calc(100% - 2px);
  height: calc(100% - 4px);
  border: 10px solid transparent;
  border-image-source: url(Assets/tab_gold_right.png);
  border-image-slice: 12 fill;
  border-image-repeat: round;
  box-sizing: border-box;
}

.craftingLogLevelIcon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 18px;
  height: 18px;
  background: url(Assets/log_spritesheet1.png) 0px -22px;
  background-size: 80px;
}

.craftingLogLevelIconActive {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 18px;
  height: 18px;
  background: url(Assets/log_spritesheet1.png) -19.5px -22px;
  background-size: 80px;
}

.craftingLogOthersIcon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 18px;
  height: 18px;
  background: url(Assets/log_spritesheet1.png) -39px -22px;
  background-size: 80px;
}

.craftingLogOthersIconActive {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 18px;
  height: 18px;
  background: url(Assets/log_spritesheet1.png) -58.5px -22px;
  background-size: 80px;
}

.craftingLogMasterIcon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 18px;
  height: 18px;
  background: url(Assets/log_spritesheet1.png) 0px -41px;
  background-size: 80px;
}

.craftingLogMasterIconActive {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 18px;
  height: 18px;
  background: url(Assets/log_spritesheet1.png) -20px -41px;
  background-size: 80px;
}

.craftingLogCategoriesContainer {
  width: 100%;
  max-height: 340px;
  margin-top: 8px;
  overflow: auto;
  overflow-x: hidden;
  cursor: url(Assets/Cursor/cursor-link.png) 10 0, auto;
}

.craftingLogCategorySlot {
  display: flex;
  position: relative;
  width: calc(100% - 5px);
  height: 22px;
  margin-top: 5px;
  margin-bottom: 5px;
  align-items: center;
}

.craftingLogCategoryBackground {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 5px solid transparent;
  border-image: url(Assets/button_square_silver.png) 7 fill round;
  box-sizing: border-box;
}

.craftingLogCategoryButtonClose {
  height: 18px;
  width: 18px;
  display: inline-block;
  margin-left: 3px;
  background: url(Assets/log_spritesheet2.png) -24px -3px;
  background-size: 86px;
  z-index: 0;
}

.craftingLogCategoryButtonOpen {
  height: 18px;
  width: 18px;
  display: inline-block;
  margin-left: 3px;
  background: url(Assets/log_spritesheet2.png) -61.5px -25px;
  background-size: 80px;
  z-index: 0;
}

.craftingLogCategoryText {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  display: inline-block;
  margin-left: 3px;
  font-size: 14px;
  transform: scale(1, 0.9);
  color: rgb(234, 234, 234);
}

.craftingLogMiddleBar {
  width: 332px;
  height: 548px;
  margin-top: 10px;
}

.craftingLogRecipeList {
  height: 380px;
  overflow: auto;
  overflow-x: hidden;
  margin-left: 5px;
  margin-right: 7px;
}

.craftingLogRecipeListEmpty {
  display: flex;
  height: 424px;
  align-items: center;
  justify-content: center;
}

.craftingLogRecipeEntry {
  position: relative;
  display: flex;
  height: 30px;
  width: 310px;
  margin-bottom: 6px;
  align-items: center;
  cursor: url(Assets/Cursor/cursor-link.png) 10 0, auto;
  text-overflow: ellipsis;
}

.craftingLogRecipeEntry:hover > div > label {
  display: block;
}

.craftingLogRecipeEntryImage {
  display: flex;
  position: relative;
  height: 32px;
  width: 32px;
  justify-content: center;
  align-items: center;
}

.craftingLogRecipeEntryImage img {
  position: absolute;
  height: 30px;
  width: 30px;
  border-radius: 4px;
  box-shadow: 0px 2px 2px 0px rgb(23, 23, 23), 1px 0px 2px 0px rgb(23, 23, 23),
    -1px 0px 2px 0px rgb(23, 23, 23);
}

.craftingLogRecipeEntryImageFilter {
  position: absolute;
  height: 100%;
  width: 100%;
  background: url(Assets/item_border.png) -92.5px -6px;
  background-size: 160px;
  pointer-events: none;
}

.craftingLogRecipeEntryInfo {
  position: relative;
  display: inline-block;
  margin-left: 2px;
}

.craftingLogRecipeEntryName {
  width: 250px;
  font-size: 15px;
  transform: scale(1.1, 1);
  transform-origin: left;
  margin: 0;
  margin-left: 3px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.craftingLogRecipeEntryStats {
  display: flex;
  margin-left: 3px;
  margin-top: -4px;
  font-size: 13px;
  align-items: center;
}

.craftingLogRecipeEntryLevel {
  display: inline-block;
  transform: scale(1.2, 1);
  transform-origin: left;
  color: rgb(155, 134, 113);
  margin: 0;
}

.craftingLogRecipeEntryStars {
  display: inline-block;
  color: rgb(155, 134, 113);
  margin-top: -1px;
  margin-left: 20px;
  z-index: 0;
}

.craftingLogRecipeEntryHover {
  display: none;
  position: absolute;
  height: 32px;
  width: 277px;
  border-radius: 4px;
  background-color: rgba(25, 25, 25, 0.5);
  box-shadow: inset 0 0 3px 1px rgba(49, 49, 49, 1);
  pointer-events: none;
}

.craftingLogRecipeEntryActive {
  position: absolute;
  height: 32px;
  width: 277px;
  border-radius: 4px;
  background-color: rgba(114, 93, 68, 0.8);
  box-shadow: inset 0 0 1px 1px rgba(49, 49, 49, 1);
  pointer-events: none;
}

.rarityPink {
  color: rgb(228, 144, 178);
}

.rarityGreen {
  color: rgb(141, 214, 141);
}

.rarityBlue {
  color: rgb(63, 110, 206);
}

.rarityPurple {
  color: rgb(183, 110, 218);
}

.craftingLogPageScrollSection {
  position: relative;
  width: 100%;
  height: 28px;
  margin-top: 8px;
  margin-bottom: 8px;
}

.craftingLogPageArrows {
  display: flex;
  width: 80px;
  height: 28px;
  margin: 0 auto;
  align-items: center;
  justify-content: space-between;
}

.craftingLogPageLeft {
  width: 32px;
  height: 22px;
  background: url(Assets/log_spritesheet3.png) -2px -39px;
  background-size: 90px;
  opacity: 0.3;
}

.craftingLogPageScrollCount {
  font-family: "eurostile-extended";
  font-size: 14px;
  font-weight: bold;
  position: absolute;
  right: 8px;
  top: 8px;
  color: rgb(247, 235, 209);
  text-shadow: 0px 0px 4px rgb(241, 110, 2);
}

.craftingLogPageLeftActive {
  width: 32px;
  height: 22px;
  background: url(Assets/log_spritesheet3.png) -2px -39px;
  background-size: 90px;
  cursor: url(Assets/Cursor/cursor-link.png) 10 0, auto;
}

.craftingLogPageRight {
  width: 32px;
  height: 22px;
  background: url(Assets/log_spritesheet3.png) -2px -39px;
  background-size: 90px;
  transform: scale(-1);
  opacity: 0.3;
}

.craftingLogPageRightActive {
  width: 32px;
  height: 22px;
  background: url(Assets/log_spritesheet3.png) -2px -39px;
  background-size: 90px;
  transform: scale(-1);
  cursor: url(Assets/Cursor/cursor-link.png) 10 0, auto;
}

.craftingLogCharacteristics {
  width: calc(100% - 14px);
  height: 109px;
  padding-top: 3px;
  padding-left: 7px;
  padding-right: 7px;
}

.craftingLogCharacteristicsTitle {
  color: rgb(157, 157, 157);
  font-size: 15px;
}

.craftingLogCharacteristicsList {
  height: 91px;
  overflow: auto;
  overflow-x: hidden;
}

.craftingLogCharacteristicsEntry {
  margin-left: 15px;
  color: rgb(216, 207, 186);
  font-size: 15px;
}

.craftingLogRightBar {
  position: relative;
  width: 374px;
  height: 548px;
  margin-left: 5px;
}

.craftingLogRecipeOverview {
  display: flex;
  height: 98px;
  width: 100%;
  background-color: rgb(42, 42, 42);
  border-radius: 6px;
  box-shadow: inset 0 2px 2px 0px rgb(27, 27, 27), 0 1px 2px 0 rgb(70, 70, 70);
}

.craftingLogRecipeOverviewContainer {
  height: 80px;
  width: calc(100% - 14px);
  margin: auto;
  margin-right: 0px;
}

.craftingLogRecipeOverviewHeader {
  display: flex;
  width: 100%;
  height: 40px;
  align-items: center;
}

.craftingLogRecipeOverviewImageContainer {
  position: relative;
  display: flex;
  height: 42px;
  width: 42px;
  align-items: center;
  justify-content: center;
}

.craftingLogRecipeOverviewImageContainer img {
  height: 40px;
  width: 40px;
  border-radius: 4px;
  cursor: url(Assets/Cursor/cursor-link.png) 10 0, auto;
}

.craftingLogRecipeOverviewImageFilter {
  position: absolute;
  height: 100%;
  width: 100%;
  background: url(Assets/item_border.png) -121.5px -7px;
  background-size: 210px;
  cursor: url(Assets/Cursor/cursor-link.png) 10 0, auto;
}

.craftingLogRecipeOverviewImageContainer p {
  position: absolute;
  margin: 0;
  bottom: -5px;
  right: -1px;
  text-shadow: 0px 0px 5px rgb(0, 0, 0);
}

.craftingLogRecipeOverviewHeader p {
  font-size: 15px;
  margin-left: 7px;
  transform: scale(1.1, 1);
  transform-origin: left;
}

.craftingLogRecipeOverviewStats {
  width: calc(100% - 10px);
  display: flex;
  align-items: end;
  font-size: 15px;
}

.craftingLogRecipeOverviewStatsSlot {
  width: 20%;
  text-align: right;
}

.fontDark {
  color: #a0a0a0;
  transform: scale(1, 0.85);
  transform-origin: bottom;
}

.fontLight {
  color: rgb(216, 207, 186);
  transform: scale(1, 0.85);
  transform-origin: bottom;
}

.fontGold {
  color: #ccbf9f;
}

.fontVariableLarge {
  font-family: "eurostile-extended";
  color: rgb(157, 192, 216);
  font-size: 21px;
}

.craftingLogRecipeInventory {
  display: flex;
  margin-left: 13px;
  margin-right: 10px;
  margin-top: 3px;
  font-size: 15px;
  align-items: center;
}

.craftingLogRecipeInventorySlot {
  text-align: right;
}

.hqIcon {
  height: 13px;
  width: 13px;
  content: url(Assets/hq_icon.png);
}

.craftingLogMaterialContainer {
  width: calc(100% - 27px);
  margin-left: 18px;
}

.craftingLogCrystal {
  display: flex;
  flex-direction: row;
}

.craftingLogMaterialSlot {
  display: flex;
  flex-direction: row;
}

.craftingLogMaterialQuantity {
  position: relative;
  display: flex;
  font-family: "eurostile-extended";
  color: rgb(235, 239, 238);
  font-size: 21px;
  width: 30px;
  height: 100%;
  text-align: right;
  margin-right: 1px;
  margin-top: 3px;
}

.craftingLogMaterialQuantity p {
  position: absolute;
  margin: 0;
  right: 0;
}

.craftingLogMaterialImage {
  position: relative;
  display: flex;
  width: 44px;
  height: 46px;
  justify-content: center;
  align-items: center;
}

.craftingLogMaterialImageBackground {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url(Assets/item_slot_empty.png);
  background-size: 44px;
  pointer-events: none;
}

.craftingLogMaterialImageBackgroundFill {
  position: absolute;
  width: calc(100% - 6px);
  height: calc(100% - 6px);
  background: url(Assets/item_slot.png);
  background-size: 38px;
  pointer-events: none;
}

.craftingLogMaterialImage img {
  position: absolute;
  width: 41px;
  height: 41px;
  cursor: url(Assets/Cursor/cursor-link.png) 10 0, auto;
}

.craftingLogMaterialImage p {
  position: absolute;
  color: rgb(235, 239, 238);
  margin: 0;
  bottom: -5px;
  right: 2px;
  pointer-events: none;
}

.craftingLogRecipeMaterialImageFilter {
  position: absolute;
  height: 100%;
  width: 100%;
  background: url(Assets/item_border.png) -127.5px -6px;
  background-size: 220px;
  pointer-events: none;
}

.craftingLogMaterialHeader {
  display: inline-block;
  width: 44px;
  text-align: right;
}

.craftingLogMaterialHeaderMaterials {
  display: inline-block;
  width: 75px;
  text-align: right;
}

.craftingLogMaterialQuantitySelectContainer {
  position: relative;
  width: 92px;
  margin-top: 2px;
  margin-left: 11px;
}

.craftingLogMaterialQuantitySelectRow {
  display: inline-flex;
  width: 100%;
  text-align: center;
}

.craftingLogMaterialQuantitySelectBackground {
  display: none;
  position: absolute;
  width: 100%;
  height: 25px;
  background: url(Assets/log_spritesheet3.png) -5px -6px;
  background-size: 88px;
  bottom: -2px;
}

.craftingLogMaterialQuantitySelectLarge {
  position: relative;
  font-family: "eurostile-extended";
  width: 50%;
  font-size: 21px;
  color: rgb(204, 191, 159);
  cursor: url(Assets/Cursor/cursor-link.png) 10 0, auto;
}

.craftingLogMaterialQuantitySelectLarge:hover > label {
  display: block;
}

.craftingLogMaterialQuantitySelectLargeText {
  position: absolute;
  left: 0;
  right: 0;
  bottom: -3px;
}

.craftingLogMaterialQuantitySelectSmall {
  position: relative;
  width: 50%;
  font-size: 15px;
  margin: 0;
  color: rgb(115, 115, 115);
}

.craftingLogMaterialQuantitySelectSmall p {
  position: relative;
  margin: 0;
  top: -2px;
}

.craftingLogMaterialQuantityDivider {
  display: inline-block;
  width: 2px;
  background-color: rgb(105, 105, 105);
  box-shadow: -1px 0px rgb(23, 23, 23);
  margin-left: 8px;
  height: 35px;
  align-self: center;
}

.craftingLogMaterialName {
  font-size: 15px;
  color: rgb(220, 220, 220);
  align-items: center;
  margin-left: 5px;
  width: 158px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: 0;
}

.craftingLogSynthesisContainer {
  position: absolute;
  display: flex;
  width: 100%;
  margin-top: 24px;
  margin-left: 10px;
  margin-right: 10px;
  align-items: center;
  opacity: 0.5;
  bottom: 0;
}

.craftingLogTrialSynthesis {
  display: flex;
  position: relative;
  width: 47px;
  height: 25px;
  align-items: center;
  justify-content: center;
}

.craftingLogTrialSynthesisBackground {
  width: 100%;
  height: 100%;
  border: 11px solid transparent;
  border-image: url(Assets/button_grey.png) 11 fill round;
  box-sizing: border-box;
}

.craftingLogTrialSynthesisIcon {
  position: absolute;
  width: 18px;
  height: 18px;
  background: url(Assets/log_spritesheet3.png) 26px -106px;
  background-size: 90px;
}

.craftingLogQuickSynthesis {
  display: flex;
  position: relative;
  width: 136px;
  height: 25px;
  align-items: center;
  justify-content: center;
}

.craftingLogQuickSynthesisBackground {
  width: 100%;
  height: 100%;
  border: 11px solid transparent;
  border-image: url(Assets/button_grey.png) 11 fill round;
  box-sizing: border-box;
}

.craftingLogQuickSynthesis p {
  position: absolute;
  font-size: 15px;
  transform: scale(1, 0.9);
}

.craftingLogSynthesis {
  display: flex;
  position: relative;
  width: 136px;
  height: 32px;
  align-items: center;
  justify-content: center;
  margin-left: 3px;
  margin-right: 3px;
}

.craftingLogSynthesisBackground {
  width: 100%;
  height: 100%;
  border: 11px solid transparent;
  border-image: url(Assets/button_gold.png) 11 fill stretch;
  box-sizing: border-box;
}

.craftingLogSynthesis p {
  position: absolute;
  font-size: 17px;
  transform: scale(1, 0.9);
  color: rgb(255, 253, 202);
  text-shadow: none;
}

@keyframes qualityUp {
  100% {
    transform: translateY(-8px);
  }
}

.qualityUpArrow {
  position: absolute;
  height: 13px;
  width: 9px;
  background: url(Assets/log_spritesheet3.png) -3px -114px;
  background-size: 90px;
  right: 0;
  top: 5px;
  animation-name: qualityUp;
  animation-duration: 1s;
  animation-iteration-count: infinite;
}

.craftingLogSearchQueryIcon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 18px;
  height: 18px;
  background: url(Assets/log_spritesheet1.png) -1px -3px;
  background-size: 85px;
}

.craftingLogSearchQueryIconActive {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 18px;
  height: 18px;
  background: url(Assets/log_spritesheet1.png) -22px -3px;
  background-size: 85px;
}

.craftingLogSearchOriginalIcon {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 19px;
  height: 18px;
  background: url(Assets/log_spritesheet1.png) -42px -3px;
  background-size: 85px;
}

.craftingLogSearchOriginalIconActive {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 19px;
  height: 18px;
  background: url(Assets/log_spritesheet1.png) -63px -3px;
  background-size: 85px;
}

.itemInfoModal {
  position: absolute;
  width: 370px;
  top: 50px;
  left: 50px;
  z-index: 1;
  padding-bottom: 15px;
}

.itemInfoModalBackground {
  position: absolute;
  height: 96%;
  width: 100%;
  border: 30px solid transparent;
  border-image: url(Assets/modal.png) 30 fill round;
  box-sizing: border-box;
}

.itemInfoModalHeader {
  display: flex;
  width: calc(100% - 30px);
  margin: 0 auto;
  margin-top: 15px;
  align-items: center;
}

.itemInfoModalImageContainer {
  display: flex;
  position: relative;
  height: 42px;
  width: 42px;
  align-items: center;
  justify-content: center;
}

.itemInfoModalImageContainer img {
  height: 40px;
  width: 40px;
  border-radius: 4px;
  cursor: url(Assets/Cursor/cursor-pointer.png), auto;
}

.itemInfoModalHeaderTitle {
  display: flex;
  position: relative;
  font-size: 15px;
  margin-left: 7px;
  transform: scale(1.1, 1);
  transform-origin: left;
  top: 0;
  bottom: 0;
}

.itemInfoModalDetails {
  display: flex;
  flex-direction: row;
  width: calc(100% - 26px);
  margin: 0 auto;
  margin-top: 3px;
  align-items: center;
  font-size: 13px;
  justify-content: space-between;
  transform: scale(1);
}

.itemInfoModalDetails p {
  position: relative;
  font-size: 13px;
  margin: 0;
}

.itemInfoModalLevel {
  position: relative;
  display: flex;
  width: calc(100% + 30px);
  height: 13px;
  margin-top: 3px;
  margin-left: -15px;
  background-color: rgba(10, 10, 10, 0.5);
  align-items: center;
}

.itemInfoModalLevel p {
  font-family: "eurostile";
  position: absolute;
  top: 0px;
  right: auto;
  left: 8px;
  font-size: 14.5px;
  transform: scale(1.55, 0.9);
  text-shadow: 0px 0px 1px rgb(220, 220, 220);
}

.itemInfoModalDescription {
  position: relative;
  white-space: pre-wrap;
  margin-left: 15px;
  margin-right: 15px;
  margin-top: 8px;
  font-size: 14.5px;
  line-height: 90%;
  transform: scale(1, 0.95);
  transform-origin: top;
}

.itemModalEffectsName {
  color: rgb(160, 160, 160);
  margin-top: 5px;
}

.hqItemOverlay {
  position: absolute;
  height: 40px;
  width: 40px;
  background: url(Assets/hq_overlay.png);
  top: -1px;
  filter: brightness(130%);
}

.gilSymbol {
  display: inline-block;
  height: 9px;
  margin-left: 2px;
  content: url(Assets/gil.svg);
}

.itemInfoModalExtraStats {
  display: flex;
  position: relative;
  height: 55px;
  width: 100%;
  margin: 5px auto 0 auto;
}

.itemInfoModalExtraStatsEntry {
  position: relative;
  width: calc(100% / 3);
  text-align: right;
  padding-left: 2px;
  padding-right: 2px;
}

.itemInfoModalExtraStatsEntryTitle {
  color: rgb(204, 191, 159);
  font-size: 14px;
  margin-bottom: 10px;
}

.itemInfoModalExtraStatsEntryText {
  position: relative;
  font-family: "trump";
  font-size: 25px;
  margin-right: 2px;
  z-index: 10;
}

.itemInfoModalExtraStatsEntryHighlight {
  position: relative;
  width: calc(100% - 4px);
  height: 7px;
  background-color: rgba(100, 100, 100, 0.5);
  z-index: 0;
  border-radius: 3.5px;
}

.itemInfoModalBonusStats {
  display: flex;
  margin: 5px;
  flex-wrap: wrap;
  gap: 7px 0px;
}

.materia {
  width: 18px;
  height: 18px;
  background: url(Assets/materia.png) -3px -15px;
  margin-bottom: 6px;
  margin-left: -3px;
}

.craftRepairGrid {
  display: grid;
  grid-template-columns: 37% auto;
  margin-top: 5px;
  margin-bottom: 5px;
  row-gap: 3px;
}

.searchResultHeader {
  color: rgb(20, 15, 11);
  width: calc(100% - 7px);
  height: 19px;
  background-color: rgb(116, 109, 103);
  text-shadow: 0px 0px rgb(24, 24, 24);
  border-radius: 7px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 3px;
  padding-bottom: 1px;
  transform: scale(1, 0.9);
}

.contextBlocker {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border: 30px solid transparent;
  z-index: 2;
}

.contextMenu {
  display: flex;
  position: fixed;
  height: fit-content;
  cursor: url(Assets/Cursor/cursor-link.png) 10 0, auto;
  padding: 5px;
  justify-content: center;
  z-index: 3;
  color: rgb(216, 207, 186);
}

.contextMenuBackground {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 15px solid transparent;
  border-image: url(Assets/context_menu.png) 20 fill round;
  box-sizing: border-box;
}

.contextMenuContent {
  position: relative;
  display: grid;
  row-gap: 2px;
  margin-top: 10px;
  margin-left: 3px;
}

.contextMenuEntry {
  position: relative;
  width: 100%;
  margin-right: 38px;
}

.contextMenuEntry p {
  position: relative;
  margin: 0;
  margin-left: 12px;
}

.contextMenuEntry:hover > label {
  display: block;
}

.contextMenuHover {
  display: none;
  position: absolute;
  height: 23px;
  width: 100%;
  border: 11px solid transparent;
  border-image: url(Assets/highlight.png) 20 fill stretch;
  pointer-events: none;
  top: -2px;
  box-sizing: border-box;
}

.favoritePin {
  position: absolute;
  height: 16px;
  width: 16px;
  background: url(Assets/log_spritesheet3.png) 0px -64px;
  background-size: 90px;
  bottom: 0;
  right: 5px;
}

.menuDividerHorizontal {
  width: 100%;
  height: 2px;
  background-color: rgb(77, 77, 77);
  box-shadow: 0px -1px rgb(23, 23, 23);
  margin: 0;
}

.searchInput {
  font-family: "Segoe UI", Tahoma, Geneva, Verdana, sans-serif;
  width: 100%;
  background-color: rgb(85, 85, 85);
  border-radius: 4px;
  border: none;
  box-shadow: inset 0px -1px rgb(102, 102, 102),
    inset 0px 1px 2px 1px rgb(68, 68, 68);
  color: white;
  padding-left: 5px;
  cursor: url(Assets/Cursor/cursor-text.png) 16 16, auto;
  font-size: 25px;
  box-sizing: border-box;
}

.searchInput:focus {
  outline: 2px solid white;
  outline-offset: -1px;
  box-shadow: inset 0 0 3px 3px rgb(179, 148, 65), 0 0 3px 3px rgb(179, 148, 65);
}

/* ============================== */
/* Custom Scrollbar
/* ============================== */

/* width */
::-webkit-scrollbar {
  width: 5px;
  margin-bottom: 20px;
}

/* Track */
::-webkit-scrollbar-track {
  background: none;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(121, 121, 121);
  border-radius: 3px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(131, 131, 131);
}

@media only screen and (max-width: 900px) {
  .mainContent {
    min-height: calc(100vh - 82px);
  }
  .menu {
    width: 100%;
  }
}
