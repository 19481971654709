@font-face {
  font-family: "trajan";
  src: url("./Fonts/trajanpro-regular-webfont.woff2") format("woff2"),
    url("./Fonts/trajanpro-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

header {
  display: flex;
  align-items: center;
  padding: 10px;
  justify-content: space-between;
}

.xivlibraryTitle {
  display: flex;
  align-items: center;
}

.xivlibraryLogo {
  cursor: url(Assets/Cursor/cursor-link.png) 10 0, auto;
  height: 50px;
}

.xivlibraryText {
  cursor: url(Assets/Cursor/cursor-link.png) 10 0, auto;
  height: 40px;
}

.configButtonContainer {
  position: relative;
  display: inline-block;
  margin-left: 2px;
  margin-right: 2px;
}

.configButton {
  height: 30px;
  width: 30px;
  cursor: url(Assets/Cursor/cursor-link.png) 10 0, auto;
}

.configMenu {
  display: flex;
  flex-direction: column;
  position: absolute;
  cursor: url(Assets/Cursor/cursor-link.png) 10 0, auto;
  padding: 5px;
  justify-content: center;
  z-index: 3;
  color: rgb(216, 207, 186);
  white-space: nowrap;
  right: 0;
  align-items: center;
}

.configMenuBackground {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 15px solid transparent;
  border-image: url(Assets/context_menu.png) 20 fill round;
  box-sizing: border-box;
}

.configMenuContent {
  position: relative;
  display: grid;
  row-gap: 2px;
  margin-top: 5px;
  margin-left: 2px;
  margin-right: 20px;
  margin-bottom: 5px;
}

.configMenuEntry {
  position: relative;
  width: 100%;
}

.configMenuEntry p {
  position: relative;
  margin: 0;
  margin-left: 12px;
}

.configMenuEntry:hover > label {
  display: block;
}

.configMenuHover {
  display: none;
  position: absolute;
  height: 23px;
  width: 100%;
  border: 11px solid transparent;
  border-image: url(Assets/highlight.png) 20 fill stretch;
  pointer-events: none;
  top: -2px;
  box-sizing: border-box;
}

footer {
  font-size: 15px;
  text-align: center;
  padding-bottom: 5px;
}

footer p {
  margin: 5px;
}

a {
  cursor: url(Assets/Cursor/cursor-link.png) 10 0, auto;
  color: inherit;
  text-decoration: none;
}

.navbar {
  display: flex;
  justify-content: space-around;
  margin-top: 0px;
  margin-bottom: 5px;
}

.navbarCategory {
  position: relative;
  font-family: "trajan";
  font-size: 25px;
  cursor: url(Assets/Cursor/cursor-link.png) 10 0, auto;
}

.navbarCategory p {
  margin: 0;
}

.navbarCategoryBorder {
  position: absolute;
  width: 100%;
  height: calc(100% - 10px);
  border: 10px solid transparent;
  border-image: url(Assets/regular_border.png) 20 round;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.navbarCategoryMenu {
  position: absolute;
  font-family: "myriad";
  font-size: 20px;
  width: fit-content;
  color: rgb(216, 207, 186);
  z-index: 1;
  top: 40px;
  left: -10px;
  white-space: nowrap;
  padding-top: 10px;
  padding-bottom: 10px;
}

.navbarCategoryMenuBackground {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 20px solid transparent;
  border-image: url(Assets/context_menu.png) 20 fill round;
  box-sizing: border-box;
  z-index: -1;
  left: 0;
  top: 0;
}

.navbarCategoryMenuEntry {
  display: flex;
  position: relative;
  width: 100%;
  margin-left: 15px;
  margin-right: 30px;
  margin-bottom: 5px;
  align-items: center;
}

.navbarCategoryMenuEntry:hover > label {
  display: block;
}

.navbarCategoryMenuEntryHover {
  display: none;
  position: absolute;
  height: 30px;
  width: calc(100% - 50px);
  border: 15px solid transparent;
  border-image: url(Assets/highlight.png) 20 fill stretch;
  pointer-events: none;
  box-sizing: border-box;
  left: 30px;
  top: -2px;
}

.navbarCategoryMenuEntry p {
  display: inline-block;
  position: relative;
}

.navbarCategoryMenuEntry img {
  display: inline-block;
  height: 30px;
  width: 30px;
  margin-right: 10px;
}

.navbarDivider {
  height: 6px;
  background: url(Assets/horizontal_border.png) 0px 0px;
}

.navbarMobile {
  display: none;
  position: relative;
}

.navbarMobileMenu {
  position: absolute;
  margin-top: 16px;
  padding-top: 10px;
  width: 100vw;
  background-color: #333333;
  left: -10px;
  z-index: 1;
  max-height: 75vh;
  overflow: auto;
  border-bottom: 7px solid red;
  border-image: url(Assets/horizontal_border.png) 8 round;
}

.navbarMobileMenu::-webkit-scrollbar {
  width: auto;
}

.navbarMobileMenu::-webkit-scrollbar-track {
  margin-bottom: 10px;
}

.navbarMobileMenuCategory {
  font-family: "trajan";
  font-size: 25px;
  margin-left: 10px;
}

.navbarMobileMenuEntryContainer {
  display: flex;
  flex-direction: column;
  margin-left: 25px;
  font-size: 20px;
  margin-bottom: 20px;
}

.navbarMobileMenuEntry {
  color: rgb(216, 207, 186);
  margin: 5px;
}

.underConstruction {
  color: rgb(50, 50, 50);
}

.underConstructionMobile {
  color: rgb(102, 95, 82);
  text-decoration: line-through;
}

.homeContainer {
  width: 60%;
  margin: 0 auto;
}

.homeContainer h1 {
  font-family: "trajan";
  text-align: center;
}

.homeCategory {
  font-family: "trajan";
  font-size: 35px;
  text-decoration: overline underline;
  text-decoration-thickness: 1px;
  text-underline-offset: 7px;
  margin-bottom: 5px;
}

.homeEntryContainer {
  display: flex;
  margin-bottom: 20px;
  flex-wrap: wrap;
}

.homeEntry {
  display: flex;
  align-items: center;
  cursor: url(Assets/Cursor/cursor-link.png) 10 0, auto;
  width: 25%;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-right: 5px;
  box-sizing: border-box;
}

.homeEntry img {
  margin-right: 10px;
}

.homeEntry p {
  display: inline-block;
  font-size: 20px;
  margin: 0;
}

.homeUnderConstruction {
  opacity: 0.5;
}

.login {
  font-family: "trajan";
  text-align: center;
  margin-bottom: 30px;
}

.loginContainer {
  position: relative;
  margin: 0 auto;
  width: fit-content;
}

.loginDetails {
  display: grid;
  grid-template-columns: 200px 300px;
  font-size: 30px;
  margin-bottom: 20px;
}

.loginDetails label {
  cursor: url(Assets/Cursor/cursor-pointer.png), auto;
}

.loginButtonContainer {
  display: flex;
  width: 30%;
  margin: 0 auto;
}

.loginButton {
  display: flex;
  position: relative;
  width: 150px;
  height: 40px;
  font-size: 22px;
  margin: 0 auto;
  align-items: center;
  justify-content: center;
  cursor: url(Assets/Cursor/cursor-link.png) 10 0, auto;
  font-family: "myriad";
}

.loginButtonGold {
  position: relative;
  color: rgb(255, 253, 202);
}

.loginButtonGray {
  position: relative;
  color: rgb(220, 220, 220);
}

.loginSubmitBackground {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 11px solid transparent;
  border-image: url(Assets/button_gold.png) 11 fill stretch;
  box-sizing: border-box;
}

.loginRegisterBackground {
  position: absolute;
  width: 100%;
  height: 100%;
  border: 11px solid transparent;
  border-image: url(Assets/button_grey.png) 11 fill stretch;
  box-sizing: border-box;
}

.searchBarContainer {
  width: 300px;
  height: 100px;
  z-index: 1;
}

.searchBar {
  width: 100%;
}

.searchBar:focus + .searchResults {
  display: block;
}

.searchResults {
  display: none;
  max-height: 300px;
  width: 100%;
  background-color: red;
  overflow: scroll;
  overflow-x: hidden;
}

.searchResults:active {
  display: block;
}

.menuRegular {
  position: relative;
  width: 60%;
  margin: 0 auto;
  color: rgb(220, 220, 220);
}

.menuRegularBackground {
  position: absolute;
  height: 96%;
  width: 100%;
  border: 30px solid transparent;
  border-image: url(Assets/modal.png) 30 fill round;
  box-sizing: border-box;
}

.menuRegularContent {
  position: relative;
  padding: 15px;
}

.gilIcon {
  display: inline-block;
  height: 30px;
  content: url(Assets/gil_icon.png);
}

@media only screen and (max-width: 1280px) {
  .homeContainer {
    width: 80%;
  }
}

@media only screen and (max-width: 900px) {
  .homeContainer {
    width: 95%;
  }
  .homeContainer h1 {
    display: none;
  }
  .homeEntry {
    width: 50%;
  }
  .homeEntry img {
    margin-right: 5px;
  }
  .homeEntry p {
    font-size: 18px;
  }
  .homeCategory {
    font-size: 25px;
  }
  .xivlibraryLogo {
    display: none;
  }
  .xivlibraryText {
    height: 30px;
  }
  .navbar {
    display: none;
  }
  .navbarMobile {
    display: inline-block;
  }
  footer {
    font-size: 13px;
  }
  .loginContainer {
    width: 95%;
    right: 5px;
  }
  .loginContainer label {
    font-size: 25px;
    margin-left: 5px;
    margin-right: 5px;
  }
  .loginDetails {
    grid-template-columns: auto auto;
  }
  .loginButtonContainer {
    width: 95%;
  }
  .menuRegular {
    width: 95%;
  }
}
